import throttle from '../utils/throttle'

export default class Parallax {
    constructor() {
        if (window.innerWidth > 990 ) {
            this.parallax = document.querySelectorAll('.parallax');

            this.parallax.forEach((el) => {
                const dataParams = el.getAttribute('data-params')
                const params = dataParams ? JSON.parse(dataParams) : {}
                params.speed = params.speed ? params.speed : 1
                Parallax.init(el, params)
                window.addEventListener('scroll', throttle(() => {Parallax.move(el, params)}, 100))
            })
        }
    }

    static init(el, params) {
        if (el.classList.contains('parallax-default')) { return }
        if (params && params.minWidth && window.innerWidth < params.minWidth ) { return }
        el.style.transform = `translate3d(0, ${(100 / 10) * params.speed}%, 0)` 
    }

    static move(el, params) {
        const position = el.getBoundingClientRect();
        if (position.top > window.innerHeight || position.bottom <= 200) { return }
        if (params && params.minWidth && window.innerWidth < params.minWidth ) { return }

        el.style.transform = `translate3d(0, ${(position.top * 100 / window.innerHeight) / 10* params.speed}%, 0)`
    }
}