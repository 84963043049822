import throttle from "../utils/throttle";

export default class Video {
  constructor() {
    this.videos = [].slice.call(document.querySelectorAll('video'));

    this.videos.forEach((video, i) => {
      this.autoplay(video, i)
    })

    window.addEventListener('scroll', throttle(() => {
      this.videos.forEach((video, i) => {
        this.autoplay(video, i)
      })
    }, 500))
  }
  
  autoplay(video, i) {
    video.classList.contains('loaded')
    // if element not visible
    const position = video.getBoundingClientRect();
    if(position.top > window.innerHeight || position.bottom <= 0) { return }
    
    const sources = video.querySelectorAll('source')
    sources.forEach((_el) => {
      const src = _el.getAttribute('data-src')
      _el.setAttribute('src', src)
      _el.setAttribute('data-src', '')
    })
    video.load()
    video.classList.add('loaded')
    this.videos.splice(i, 1)
  }
}