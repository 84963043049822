// Componenents
import Header from'./components/Header';
import Slider from'./components/Slider';
import Modal from'./components/Modal';
import Accordion from'./components/Accordion';
import Marquee from'./components/Marquee';
import Animate from'./components/Animate';
import Parallax from'./components/Parallax';
import Video from'./components/Video';
import Sticky from'./components/Sticky';
import ClickableDiv from'./components/ClickableDiv';
import SinglePost from'./components/SinglePost';
import PrerenderLinks from'./components/PrerenderLinks';
import TextMore from'./components/TextMore';
import Form from'./components/Form';
import LazyBg from "./components/LazyBg";

// Contact
import Contact from'./pages/Contact';

const classes =  {
    // Componenents
    header: ['.header', Header],
    slider: ['.slider', Slider],
    modal: ['.modal__button, .modal__button-hover', Modal],
    accordion: ['.accordion__button', Accordion],
    marquee: ['.marquee', Marquee],
    animate: ['.animate', Animate],
    parallax: ['.parallax', Parallax],
    video: ['video', Video],
    sticky: ['.sticky, .front-page-section-header__container', Sticky],
    clickableDiv: ['.clickable-div', ClickableDiv],
    SinglePost: ['.single-post', SinglePost],
    prerenderLinks: ['a', PrerenderLinks],
    textMore:['.text-more', TextMore],
    form: ['.wpcf7-form', Form],
    lazybg: ['.lazy-bgdom', LazyBg],
    
    // Pages
    contact: ['.contact', Contact],
};

const instances = {};

for (const element in classes) {
    if (document.querySelector(classes[element][0])) {
        const _class = classes[element][1];
        instances[element] = new _class();
    }
}
