export default class ArticlePreview {
    constructor() {
        this.preview = document.querySelectorAll('.clickable-div');

        this.preview.forEach((el) => {
            el.addEventListener('mousedown', (e) => {
                ArticlePreview.startClick(el, e)
            }, {passive: true})
            el.addEventListener('touchstart', (e) => {
                ArticlePreview.startClick(el, e)
            }, {passive: true})
            el.addEventListener('mouseup', (e) => {
                ArticlePreview.openLink(el, e)
            }, {passive: true})
            el.addEventListener('touchend', (e) => {
                ArticlePreview.openLink(el, e)
            }, {passive: true})
            el.addEventListener('mouseover', () => {
                el.querySelector('a.clickable-div__link').classList.add('hover')
            })
            el.addEventListener('mouseleave', () => {
                el.querySelector('a.clickable-div__link').classList.remove('hover')
            })
        })
    }

    static startClick(el, e) {
        let initialX
        el.setAttribute('data-event',  Date.now())
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX;
        } else {
            initialX = e.clientX;
        }

        el.setAttribute('data-eventX', initialX)
    }

    static openLink(el, e) {
        const link = el.querySelector('a.clickable-div__link')
        const clickTime = 500
        let initialX

        if (e.type === "touchend") {
            initialX = e.touches[0].clientX;
        } else {
            initialX = e.clientX;
        }

        if (el.getAttribute('data-eventX') - initialX > 15 ) { return }
        if (el.getAttribute('data-eventX') - initialX < -15 ) { return }
        if (Date.now() - el.getAttribute('data-event') > clickTime ) { return }
        if (!link) { return }
        window.location.href = link.getAttribute('href')
    }
}