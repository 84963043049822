import throttle from '../utils/throttle';

export default class Header {
    constructor() {
        this.header = document.querySelector('.header');
        this.button = this.header.querySelector('.header__burger');
        this.button.addEventListener('click', this.toogleBurgerMenu.bind(this))

        window.addEventListener('scroll', () => {this.onScroll()})
        this.scrollY = false

        this.animLogo()
    }

    toogleBurgerMenu(e) {
        e.preventDefault()
        this.header.classList.toggle('open')
    }

    onScroll() {
        const tolerence = 40
        if (this.header.classList.contains('visible')) {
            if (window.scrollY <= tolerence + 80) {
                this.header.classList.remove('scrolled')
            } else {
                this.header.classList.add('scrolled')
            }
        } else {
              if (window.scrollY <= tolerence) {
                    this.header.classList.remove('scrolled')
              } else {
                    this.header.classList.add('scrolled')
              }
        }
        
        if (this.scrollY <= window.scrollY && this.scrollY !== false) {
            this.header.classList.remove('visible')
        } else {
            this.header.classList.add('visible')
        }
        this.scrollY = window.scrollY
    }

    async animLogo() {
        const parent = document.querySelector('.header__logo-link'),
            animated = parent.getAttribute('data-animated');
        if (!animated) { return }
        import(/* webpackChunkName: "lottie" */ 'lottie-web').then((lottie) => {
            parent.innerHTML = '';
            lottie.loadAnimation({
                container: parent, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: animated // the path to the animation json
            });
        });
    }
}
