import throttle from '../utils/throttle'

export default class Marquee {
    constructor() {
        this.marquee = document.querySelectorAll('.js-marquee')
        //this.lastPos = 0
        this.marquee.forEach((el) => {
          Marquee.calculateWith(el)
            /*
              this.lastScrollPos = el.getBoundingClientRect().top
              document.addEventListener('scroll', throttle(
                () => {
                  Marquee.slideLine(el, this)
                }, 100))

              Marquee.slideLine(el, this)
            */
        })
    }
    static calculateWith(el) {
      const container = el.querySelector('.js-marquee-first')
      const container2 = el.querySelector('.js-marquee-second')
      const element = container.querySelectorAll('.marquee__line__content')
      let element2 = null;
      if (container2) {
        element2 = container2.querySelectorAll('.marquee__line__content')
      }
      let pageWidth = document.body.offsetWidth
      let containerWidth = container.offsetWidth

      while (containerWidth < pageWidth * 2) {
        const newElement = element[0].cloneNode(true)
        container.appendChild(newElement)
        if (container2) {
          const newElement2 = element2[0].cloneNode(true)
          container2.prepend(newElement2)
        }
        containerWidth += newElement.offsetWidth
      }

      el.classList.add('marquee--loaded')
    }
    /*
    static slideLine(elem, context) {
      let elPos = elem.getBoundingClientRect()
      if (elPos.top + elPos.height >= 0 && elPos.bottom - elPos.height <= window.innerHeight) {
        let newpos = context.lastPos + window.scrollY - context.lastScrollPos
        if (newpos > window.innerWidth || newpos < -window.innerWidth) {
          //Do nothing
        } else {
          elem.querySelector('.js-marquee-first').style.transform = 'translateX(' + newpos * 0.5 + 'px)'
          elem.querySelector('.js-marquee-second').style.transform = 'translateX(' + newpos * 0.5 * -1 + 'px)'
          context.lastPos = newpos
        }
      }
      else {
        context.lastPos = 0
        elem.querySelector('.js-marquee-first').style.transform = 'translateX(' + 0 + 'px)'
        elem.querySelector('.js-marquee-second').style.transform = 'translateX(' + 0 + 'px)'
      }
      context.lastScrollPos = window.scrollY
    }
    */

}
