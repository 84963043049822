import throttle from '../utils/throttle'

export default class Animation {
    constructor() {
        this.animate = document.querySelectorAll('.animate');

        this.animate.forEach((el) => {
            if (el.classList.contains('animate--default')) {
                setTimeout(() =>{Animation.reveal(el)}, 500)
            } else {
            ['scroll', 'slide'].forEach((event) => {
                window.addEventListener(event, throttle(
                    () => {
                        Animation.reveal(el)
                    }, 300)
                )
            })
        }
        })
    }

    static reveal(el) {
        const position = el.getBoundingClientRect();

        if(position.top > window.innerHeight || position.bottom <= 0) { return }
        if(position.left > window.innerWidth || position.right <= 0) { return }

        el.classList.add('animate--visible')
    }


}