export default class Accordion {
    constructor() {
        this.accordions = document.querySelectorAll('.accordion__button');
        
        this.accordions.forEach((el) => {
            el.addEventListener('click', this.toggleAccordion.bind(this))
        })
    }

    toggleAccordion(e) {
        e.preventDefault()
        const currentTarget = e.currentTarget
        const accordionSelector = currentTarget.getAttribute('data-accordion')
        document.querySelectorAll(`.accordion-content.active:not(${accordionSelector}`).forEach((el) => {
            el.classList.remove('active')
            if (el.classList.contains('faq__answer')) {
              el.style.height = 0;
            }
        })
        document.querySelectorAll(`.accordion__button:not([data-accordion="${accordionSelector}"])`).forEach((el) => {
          el.classList.remove('active')
        })
        const content = document.querySelector(`.accordion-content${accordionSelector}`)
        content.classList.toggle('active')
        currentTarget.classList.toggle('active')
        if (content.classList.contains('faq__answer')) {
          if (content.classList.contains('active')) {
            content.style.height = content.querySelector('.faq__answer__content').clientHeight + 'px';
          } else {
            content.style.height = 0;
          }
        }
    }

}
