export default class Modal {
    constructor() {
        this.header = document.querySelector('.header');
        this.buttons = document.querySelectorAll('.modal__button');
        this.buttonsHover = document.querySelectorAll('.modal__button-hover');
        this.modalBg = document.querySelector('.modal__bg');

        this.modalBg.addEventListener('click', this.closeModals.bind(this))
        
        this.buttons.forEach((el) => {
            el.addEventListener('click', this.toggleModal.bind(this))
        })

        this.onResize()
        window.addEventListener('resize', this.onResize.bind(this))
    }

    onResize() {
        this.buttonsHover.forEach((el) => {
            this.closeModals.bind(this)
            if (window.innerWidth > 1079) {
              el.addEventListener('mouseenter', this.toggleModal.bind(this))
              el.addEventListener('mouseleave', this.closeModals.bind(this))
            } else {
                this.toggleModal(null, el, true)
            }
        })
    }

    toggleModal(e, target, noBackground) {
        console.log(e)
        const currentTarget = target || e.currentTarget
        
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        const modalSelector = currentTarget.getAttribute('data-modal')
        const modal = document.querySelector(modalSelector)
        if (modal.classList.contains('loading')) {
            this.removeLoading(modal)
        }
        currentTarget.classList.toggle('active')
        modal.classList.toggle('open')
        if (!noBackground && !currentTarget.classList.contains('header__item')) {
            this.modalBg.classList.toggle('active')
        }
    }

    closeModals(e) {
        document.querySelectorAll('.modal').forEach((el) => {
            el.classList.remove('open')
        })
        document.querySelectorAll('.modal__button, .modal__button-hover').forEach((el) => {
            el.classList.remove('active')
        })
        this.modalBg.classList.remove('active')
    }

    removeLoading(el) {
        el.classList.remove('loading')

       if (el.classList.contains('sub-menu')) {
            el.style.display = 'flex'
        } else {
            el.style.display = 'block'
        } 
    }
}