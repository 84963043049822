import KeenSlider from 'keen-slider'
import debounce from '../utils/debounce'

export default class Slider {
    constructor() {
        this.sliderContainers = document.querySelectorAll('.slider');
        if (!this.sliderContainers.length) { return }

        this.slideSelector = '.slider__item';
        this.defaultParams = {
            autoplayDuration: 4000,
            slides: this.slideSelector,
            buttonsContainer: false
        }

        this.links = document.querySelectorAll(`.slider ${this.slideSelector} a`)

        this.sliderContainers.forEach((el) => {
            this.initSlider(el)
        })

        this.links.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
            })
            el.addEventListener('mousedown', (e) => {
                this.startClick(el, e)
            }, {passive: true})
            el.addEventListener('touchstart', (e) => {
                this.startClick(el, e)
            }, {passive: true})
            el.addEventListener('mouseup', (e) => {
                this.triggerClick(el, e)
            }, {passive: true})
            el.addEventListener('touchend', (e) => {
                this.triggerClick(el, e)
            }, {passive: true})
        })
    }

    startClick(el, e) {
        let initialX
        el.setAttribute('data-event',  Date.now())
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX;
        } else {
            initialX = e.clientX;
        }

        el.setAttribute('data-eventX', initialX)
    }

    triggerClick(el, e) {
        e.preventDefault()
        const clickTime = 500
        let initialX

        if (e.type === "touchend") {
            initialX = e.touches[0].clientX;
        } else {
            initialX = e.clientX;
        }

        if (el.getAttribute('data-eventX') - initialX > 15 ) { return }
        if (el.getAttribute('data-eventX') - initialX < -15 ) { return }

        document.location.href = el.href
    }
    
    initSlider(el, alreadyInit) {
        const params = this.getParams(el)
        const that = this;
        const isBrekpointOk =
        (!params.minWidth && !params.maxWidth) ||
        (!params.maxWidth || window.innerWidth < params.maxWidth) &&
        (!params.minWidth || window.innerWidth > params.minWidth)

        if (isBrekpointOk && !el.slider) {
            el.slider = new KeenSlider(el, {
                ...params,
                dragStart: (slider) => {
                    that.onSliderChange.bind(that)(slider.details(), el)

                    if (params.autoplay) {
                        Slider.autoplay(false, el);
                    }
                },
                dragEnd: () => {
                    if (params.autoplay) {
                        Slider.autoplay(true, el);
                    }
                },
                afterChange(slider) {
                    that.onSliderChange.bind(that)(slider.details(), el)

                    if (params.autoplay) {
                        Slider.autoplay(true, el);
                    }
                },
                move(slider) {
                    that.onSliderChange.bind(that)(slider.details(), el)
                }
            })
        }
    
        if (params.autoplay) {
          Slider.initAutoplay(el, params.autoplayDuration)
        }

        if (params.arrows) {
          el.navContainer = el.querySelector('.js-slider-nav')
          if (el.navContainer) {
            el.prevBtn = el.navContainer.querySelector('.js-slider-left')
            el.nextBtn = el.navContainer.querySelector('.js-slider-right')
            el.prevBtn.addEventListener('click', () => {
              el.slider.prev()
            })
            el.nextBtn.addEventListener('click', () => {
              el.slider.next()
            })
          }
        }
        
        if (params.buttonsContainer) {
            el.buttons = document.querySelectorAll(`${params.buttonsContainer} .slider__button`)
            el.buttons[0].classList.add('active')
            el.buttons.forEach((e, i) => {
                e.addEventListener('click', () => {
                    el.slider.moveToSlide(i)
                })
            })
        }
        if (params.dots) {
          this.dotMarkup(el, this)
        }

        if (!alreadyInit && (params.minWidth || params.maxWidth)) {
            window.addEventListener('resize', debounce(() => {this.resize(el, params)}, 300))
        }
    }
    dotMarkup(el, context) {
        let dots = context.createDiv("dots")
        let slides = el.querySelectorAll(this.slideSelector)
        slides.forEach((_e, idx) => {
          let className = "dots__item"
          if (idx === 0) {
            className += " active"
          }
          var dot = context.createDiv(className)
          dot.addEventListener("click", () => el.slider.moveToSlide(idx))
          dots.appendChild(dot)
        })
        console.log(el, dots)
        el.appendChild(dots)
    }

    createDiv(className) {
      var div = document.createElement("div")
      var classNames = className.split(" ")
      classNames.forEach((name) => div.classList.add(name))
      return div
    }
    resize(el, params) {
        if (params.maxWidth && window.innerWidth > params.maxWidth) {
            return this.destroy(el, params)
        }
        if (params.minWidth & window.innerWidth < params.minWidth) {
            return this.destroy(el, params)
        }
        this.initSlider(el, true)
    }

    destroy(el, params) {
        if (!el.slider) { return }
        el.slider.destroy();
        el.querySelectorAll(params.slides).forEach((e) => {
            e.style.minWidth = null
        })
        el.slider = false
    }

    static initAutoplay(slider, duration) {
        slider.autoplayDuration = duration
        // slider.addEventListener('mouseover', () => {
        //     Slider.autoplay(false, slider);
        // });
        // slider.addEventListener('mouseout', () => {
        //     Slider.autoplay(true, slider);
        // });
        Slider.autoplay(true, slider);

    }

    getParams(el) {
        if (!el) { return this.defaultParams }

        const dataParams = el.getAttribute('data-params');
        if (!dataParams) { return this.defaultParams }

        const params = JSON.parse(el.getAttribute('data-params'))
        return {...this.defaultParams, ...params}
    }

    static autoplay(run, el) {
        if (!el) {return}
        clearInterval(el.interval);
        el.interval = setInterval(() => {
            const position = el.getBoundingClientRect();
            // if element not visible
            if(position.top > window.innerHeight || position.bottom <= 0) { return }
            // if not runnnig or slider not defined
            if (!run || !el.slider) { return }

            const details = el.slider.details()
            let next = details.absoluteSlide + details.slidesPerView
            el.slider.moveToSlide(next)
        }, el.autoplayDuration);
    }

    onSliderChange(e, slider) {
        this.emitEvent(e.direction);
        slider.querySelectorAll(this.slideSelector).forEach((el, i) => {
            el.classList.remove('previous', 'next')

            if (slider.buttons) {
                slider.buttons.forEach((e) => {
                    e.classList.remove('active')
                })
                slider.buttons[e.relativeSlide].classList.add('active')
            }
            let dots = slider.querySelectorAll('.dots__item')
            if (dots && dots.length > 0) {
              if (i === e.relativeSlide) {
                dots[e.relativeSlide].classList.add('active')
              } else {
                dots[i].classList.remove('active')
              }
            }
            if (i < e.relativeSlide) {
                el.classList.add('previous')
            } else if (i > e.relativeSlide) {
                el.classList.add('next')
            }
        })
    }
    
    emitEvent(direction) {
        const event = new CustomEvent('slide');
        // Dispatch the event.
        window.dispatchEvent(event, {detail: {direction}});
    }
}
