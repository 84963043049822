export default class SinglePost {
    constructor() {
        this.post = document.querySelector('.single-post');
        this.buttonContainer = document.querySelector('.single-post__rate');
        this.rate = document.querySelector('.single-post__rate-rate');
        this.buttons = document.querySelectorAll('.single-post__rate-button');

        this.buttons.forEach((el) => {
            el.addEventListener('click', (e) => {
                this.toggleButtons(e);
            })
        })

        if (this.rate) {
            this.rate.addEventListener('click', () => {
                this.buttonContainer.classList.add('active');
            })
        }
    }

    toggleButtons(e) {
        e.preventDefault();
        this.buttonContainer.classList.toggle('active');
    }
}