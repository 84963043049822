export default class Contact {
    constructor() {
        this.prefill = document.querySelector('.contact__prefill');

        this.field = document.querySelector('input[name=site]');
        if (this.prefill && this.prefill.value && this.field) {
          this.field.value = this.prefill.value
          this.textarea = document.querySelector('.wpcf7-textarea');
          if (this.textarea) {
            this.textarea.addEventListener('input', (e) => {
              this.auto_grow(e.target)
            })
          }
        }

    }
    auto_grow(element) {
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+ 1 +"px";
    }
}
