export default class Form {
    constructor() {
        this.form = document.querySelectorAll('form');
        this.cooldown = "";
        this.loader = document.createElement('div');
        this.loader.classList.add('loader-container');
        this.loader.innerHTML = '<div class="loader"></div>';
        // this.inputs = document.querySelectorAll('.wpcf7-validates-as-required');
        // this.error = false;
        // this.notValid = document.querySelectorAll('.wpcf7-not-valid-tip');

        if (this.form) {
            this.form.forEach((el) => {
              console.log(el, this.loader);
              if (el.classList.contains('front-page-services__popup') || el.classList.contains('searchandfilter')) {
                //do nothing
              } else {
                el.querySelector('.button').addEventListener('click', this.checkForm.bind(this,el))
                if (el.classList.contains('sent')) {
                  this.toaster('success', 'Demande envoyée');
                }
              }
            })
        }

        /* if (this.inputs) {
            this.inputs.forEach((el) => {
                el.addEventListener('blur', this.checkField.bind(this))
            })
        }

        if (this.notValid) {
            var text = "";
          this.notValid.forEach((el) => {
            if (text != '') {
                text += ' '
            }
            text += el.innerText;
          })
          this.toaster('error',text)
        }
        */
    }

    checkForm(e, el) {
        el.preventDefault()
        const currentTarget = e
        e.appendChild(this.loader);
        /*  const mandatory = currentTarget.querySelectorAll('.wpcf7-validates-as-required')
        this.error = false;
        if (mandatory) {
            mandatory.forEach((el) => {
                this.checkField(null, el)
            })
        }
        if (this.error == true) {
          this.toaster('error', 'Une erreur est survenue, merci de vérifier les champs')
        } else { */
            this.sendForm(currentTarget)
        /* } */
    }
    /*checkField(e, el) {
        const currentField = el || e.currentTarget
        if (currentField.value == '') {
            this.error = true;
            currentField.classList.add('error')
            if (!el) {
                // cas check field only pour eviter d'ecraser le message en cas de check form
                this.toaster('error', 'Ce champ ne peut être vide')
            }
        } else {
            if (this.error != true) {
                this.error = false;
            }
            currentField.classList.remove('error')
        }
    }*/
    sendForm(el) {
      var send = ""
      //var formData = new FormData()
      var hiddenFields = el.querySelectorAll('input[type="hidden"]')
      var fields = el.querySelectorAll('.wpcf7-form-control')
      el.classList.add('loading');
      hiddenFields.forEach((hfield) => {
        if (send == '') {
          send += '?'
        } else {
          send += '&'
        }
        //formData.append(hfield.getAttribute('name'), hfield.value)
        send += hfield.getAttribute('name') + '=' + hfield.value
      })
      fields.forEach((field) => {
        if (field.getAttribute("type") != 'submit') {
          if (field.classList.contains('wpcf7-acceptance')) {
            let input = field.querySelector('input')
            if (input.checked) {
              input.classList.remove('error')
              send += '&' + input.getAttribute('name') + '=' + input.value
            }
          } else {
            field.classList.remove('error')
            send += '&' + field.getAttribute('name') + '=' + field.value
            //formData.append(field.getAttribute('name'), field.value)
          }
        }
      })
      const id = el.querySelector('input[name="_wpcf7"]').value
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/wp-json/contact-form-7/v1/contact-forms/' + id + '/feedback', true);
      //xhr.setRequestHeader("Content-type", "multipart/form-data");
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.send(send);
      xhr.onload = (e) => {
        var data = JSON.parse(e.target.responseText);
        if (data) {
          el.classList.remove('loading');
          const form = el
          var text=""
          if (data.invalid_fields && data.invalid_fields.length > 0) {
            data.invalid_fields.forEach((input) => {
              text += '<p>' + input.message + '<p/>'
              //form.querySelector(input.into + ' input').classList.add('error')
            })
            form.querySelector('.loader-container').remove();
            this.toaster('error',text);
            return
          }
          fields.forEach((input) => {
            if (input.getAttribute("type") != 'submit') {
              input.value = ""
              input.classList.remove('error')
            }
          });
          if (typeof window.ga === 'function') {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'formulaire',
              eventAction: 'clic-bouton',
              eventLabel: 'envoyer'
            });
          }
          form.querySelector('.loader-container').remove();
          text += data.message
          this.toaster('success',text);
          location = 'https://www.uplix.fr/confirmation-formulaire';
        }
      }
    }

    toaster(type,text) {
        var toast = document.getElementById('toaster-js')
        if (toast) {
            var message = toast.querySelector('.toaster__content');
            message.innerHTML = text;
            toast.classList.add('show');
            if (type == 'error'){
                toast.classList.add('error');
            } else {
                toast.classList.remove('error');
            }
            if (this.cooldown != '') {
              window.clearTimeout(this.cooldown);
            }
            this.cooldown = window.setTimeout(() => {
                toast.classList.remove('show');
                toast.classList.remove('error')
                message.innerHtml = '';
            }, 5000)

        }
    }

}
