export default class LazyBg {
    constructor() {
        document.addEventListener("DOMContentLoaded", function() {
          const lazyBg = document.querySelectorAll('.lazy-bg');
          window.addEventListener('scroll', function() {
            lazyBg.forEach((el) => {
              const src = el.getAttribute('data-src');
              if (el.getBoundingClientRect().top < window.innerHeight) {
                el.style.backgroundImage = `url(${src})`;
              }
            });
          });
        });
    }

}
