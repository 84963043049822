import debounce from '../utils/debounce'

export default class prerenderedlinks {
    constructor() {
        this.prerenderedlinks = []
        this.links = document.querySelectorAll('a, .clickable-div')
        
        this.links.forEach((el) => {
            el.addEventListener('mouseenter', debounce(() => {this.preloadPage(el)}, 500))
        })
    }

    preloadPage(el) {
        if (el.matches(':hover')) {
            const link = el.matches('a') ? el : el.querySelector('a.clickable-div__link')
            const href = link.getAttribute('href')

            this.createPrerender(href)
        }
    }

    createPrerender(href) {
        if (!href || this.prerenderedlinks.includes(href)) {
            return
        }
        this.prerenderedlinks.push(href)

        const prerenderLink = document.createElement('link');
        prerenderLink.setAttribute('rel', 'prerender');
        prerenderLink.setAttribute('href', href);
    
        document.head.appendChild(prerenderLink);
    }
}