export default class TextMore {
    constructor() {
        this.textMore = document.querySelectorAll('.text-more');
        if (this.textMore) {
            this.textMore.forEach((el) => {
              this.isHidden(el)
            })
        }
    }

    isHidden(el) {
        const mask = el.querySelector('.text-more__mask').offsetHeight
        const text = el.querySelector('.text-more__content').offsetHeight
        if (text > mask) {
            el.querySelector('.text-more__cta').classList.add('active')
        }
    }

}